// App.jsx
import React from 'react';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery
} from '@mui/material';
import { motion } from 'framer-motion';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import FlagIcon from '@mui/icons-material/Flag';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

// Create a professional dark theme with varied colors
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2563eb', // professional blue
      light: '#3b82f6',
      dark: '#1d4ed8',
    },
    secondary: {
      main: '#22c55e', // green
      light: '#4ade80',
      dark: '#16a34a',
    },
    info: {
      main: '#0ea5e9', // sky blue
      light: '#38bdf8',
      dark: '#0284c7',
    },
    warning: {
      main: '#f59e0b', // amber
      light: '#fbbf24',
      dark: '#d97706',
    },
    background: {
      default: '#0f172a', // dark navy
      paper: '#1e293b', // slate 800
    },
    text: {
      primary: '#f8fafc', // white/light gray
      secondary: '#cbd5e1', // lighter gray
    },
    error: {
      main: '#dc2626', 
    },
    success: {
      main: '#16a34a',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h2: {
      fontWeight: 700,
      letterSpacing: '-0.02em',
    },
    h3: {
      fontWeight: 600,
      letterSpacing: '-0.02em',
    },
    h4: {
      fontWeight: 600,
      letterSpacing: '-0.01em',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '10px 20px',
          fontWeight: 600,
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: 'radial-gradient(circle at top right, rgba(37, 99, 235, 0.07), transparent 70%)',
          backgroundSize: '100% 100%',
          backgroundAttachment: 'fixed',
        },
      },
    },
  },
});

// Animation variants
const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.6 }
  }
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

function App() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      {/* Header */}
      <AppBar position="static" color="transparent" elevation={0} 
        sx={{ 
          borderBottom: '1px solid rgba(255,255,255,0.05)',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(15, 23, 42, 0.7)',
        }}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <LightbulbIcon sx={{ mr: 1, fontSize: '2rem', color: '#ffffff' }} />
            <Typography variant="h5" component="h1" sx={{ 
              fontWeight: 'bold',
              color: '#ffffff',
              textAlign: 'center',
            }}>
              LightHouse
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
      
      {/* Hero Section */}
      <Box 
        component={motion.div}
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        sx={{ 
          textAlign: 'center', 
          py: 10, 
          px: 3 
        }}
      >
        <Typography variant="h2" component="h2" gutterBottom sx={{ color: '#f8fafc' }}>
          Your AI Assistant for Studying Abroad
        </Typography>
        <Typography variant="h6" color="text.secondary" paragraph>
          📌 Studying Abroad? Get Instant Help on WhatsApp.
        </Typography>
        <Typography variant="h6" color="text.secondary" paragraph>
          📍 Visa | Housing | Jobs | Essentials – Everything You Need in One Chat.
        </Typography>
        <Typography variant="h5" sx={{ 
          mt: 3, 
          fontWeight: 600,
          color: '#f8fafc'
        }}>
          💡 Built by international students, for international students.
        </Typography>
        <Button 
          variant="contained" 
          color="secondary" 
          size="large" 
          sx={{ 
            mt: 4, 
            px: 4, 
            py: 2, 
            fontSize: '1.1rem',
          }}
          startIcon={<WhatsAppIcon />}
          component="a"
          href="https://docs.google.com/forms/d/e/1FAIpQLSca3i453fk_J_G1ASDEjZ4FsxpS31ux5q687nWl3MoIdJc6GA/viewform?usp=header"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join Waitlist
        </Button>
        
        <Box sx={{ mt: 6, display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2 }}>
            <Card sx={{ flex: 1 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <FlagIcon color="info" />
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant="subtitle1" fontWeight="bold">Visa Support</Typography>
                  <Typography variant="body2" color="text.secondary">Get accurate visa timelines and requirements</Typography>
                </Box>
              </CardContent>
            </Card>
            
            <Card sx={{ flex: 1 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <HomeIcon color="secondary" />
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant="subtitle1" fontWeight="bold">Housing Guidance</Typography>
                  <Typography variant="body2" color="text.secondary">Find safe, affordable accommodation quickly</Typography>
                </Box>
              </CardContent>
            </Card>
            
            <Card sx={{ flex: 1 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <WorkIcon color="warning" />
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant="subtitle1" fontWeight="bold">Job Opportunities</Typography>
                  <Typography variant="body2" color="text.secondary">Access visa-sponsored jobs and internships</Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
      </Box>
      
      {/* Why LightHouse Section */}
      <Container 
        component={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={staggerContainer}
        sx={{ py: 8 }}
      >
        <Typography variant="h3" component="h3" align="center" gutterBottom sx={{ color: '#f8fafc' }}>
          Why LightHouse?
        </Typography>
        <Grid container spacing={4} justifyContent="center" sx={{ mt: 2 }}>
          <Grid item xs={12} md={5}>
            <Card component={motion.div} variants={fadeIn}>
              <CardContent>
                <Typography variant="h4" component="h4" gutterBottom>
                  Before LightHouse
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <ClearIcon color="error" />
                    </ListItemIcon>
                    <ListItemText primary="Wasting hours searching for visa info but still confused." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ClearIcon color="error" />
                    </ListItemIcon>
                    <ListItemText primary="Struggling to find safe, affordable housing and roommates." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ClearIcon color="error" />
                    </ListItemIcon>
                    <ListItemText primary="Spending too much money on unnecessary services." />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card component={motion.div} variants={fadeIn}>
              <CardContent>
                <Typography variant="h4" component="h4" gutterBottom>
                  With LightHouse
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Instant visa & immigration answers." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Verified housing with AI safety maps." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Job boards focused on visa-sponsored roles." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Essential services—all in one chat." />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      
      {/* How It Works Section */}
      <Container 
        component={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={staggerContainer}
        sx={{ py: 8, bgcolor: 'rgba(30, 41, 59, 0.4)', borderRadius: 2 }}
      >
        <Typography variant="h3" component="h3" align="center" gutterBottom sx={{ color: '#f8fafc' }}>
          How It Works
        </Typography>
        <Grid container spacing={4} justifyContent="center" sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Card component={motion.div} variants={fadeIn}>
              <CardContent sx={{ textAlign: 'center', py: 4 }}>
                <Typography variant="h4" component="h4" gutterBottom color="primary">
                  Step 1
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Chat on WhatsApp
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Ask anything about visas, housing, jobs, or life abroad.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card component={motion.div} variants={fadeIn}>
              <CardContent sx={{ textAlign: 'center', py: 4 }}>
                <Typography variant="h4" component="h4" gutterBottom color="primary">
                  Step 2
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Get Verified Answers
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  AI + human support gives you real, accurate info.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card component={motion.div} variants={fadeIn}>
              <CardContent sx={{ textAlign: 'center', py: 4 }}>
                <Typography variant="h4" component="h4" gutterBottom color="primary">
                  Step 3
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Save Time & Money
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  No more guessing or wasting hours searching.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      
      {/* Testimonials Section */}
      <Container 
        component={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={staggerContainer}
        sx={{ py: 8 }}
      >
        <Typography variant="h3" component="h3" align="center" gutterBottom sx={{ color: '#f8fafc' }}>
          What Students Say
        </Typography>
        <Grid container spacing={4} justifyContent="center" sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Card component={motion.div} variants={fadeIn}>
              <CardContent sx={{ py: 4 }}>
                <Typography variant="body1" color="text.secondary" paragraph sx={{ fontStyle: 'italic' }}>
                  "LightHouse helped me with visa confusion instantly, no more searching for hours."
                </Typography>
        <Typography variant="subtitle1" fontWeight="medium" color="primary">
                  - Yash, India
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card component={motion.div} variants={fadeIn}>
              <CardContent sx={{ py: 4 }}>
                <Typography variant="body1" color="text.secondary" paragraph sx={{ fontStyle: 'italic' }}>
                  "I was looking for cultural differences on Google for 2 hours, but LightHouse gave me the answer in 30 seconds."
                </Typography>
                <Typography variant="subtitle1" fontWeight="medium" color="primary">
                  - Shubham, India
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      
      {/* Call to Action Section */}
      <Box 
        component={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeIn}
        sx={{ 
          textAlign: 'center', 
          py: 8, 
          px: 3,
          bgcolor: 'rgba(30, 41, 59, 0.4)',
          borderRadius: 2,
          mx: { xs: 2, md: 8, lg: 16 }
        }}
      >
        <Typography variant="h3" component="h3" gutterBottom color="primary">
          LightHouse is Launching Soon!
        </Typography>
        <Typography variant="h6" color="text.secondary" paragraph>
          🎯 Be the first to access it.
        </Typography>
        <Typography variant="h6" color="text.secondary" paragraph>
          💬 DM 'JOIN' on WhatsApp now and start getting instant help!
        </Typography>
        <Button 
          variant="contained" 
          color="secondary" 
          size="large" 
          sx={{ 
            mt: 4, 
            px: 4, 
            py: 2, 
            fontSize: '1.1rem',
          }}
          endIcon={<ArrowForwardIcon />}
          component="a"
          href="https://docs.google.com/forms/d/e/1FAIpQLSca3i453fk_J_G1ASDEjZ4FsxpS31ux5q687nWl3MoIdJc6GA/viewform?usp=header"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join Waitlist
        </Button>
      </Box>
      
      {/* Footer */}
      <Box 
        component="footer" 
        sx={{ 
          textAlign: 'center', 
          py: 4, 
          bgcolor: 'background.paper' 
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Be the first to access premium services. No spam, just important updates! 🚀
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default App;